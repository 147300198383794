<template>
  <b-navbar
    sticky
    justified
    fill
    style="height: 3rem; width: 100%"
    class="bg-primary text-light text-din-bold text-regular mx-auto"
  >
    <h6 class="mx-auto text-light text-din-bold text-regular mt-1 mb-1">This website will cease operation on 31st March 2024.</h6>
  </b-navbar>
</template>

<script>
export default {

}
</script>
