/* eslint-disable prefer-destructuring */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueHammer } from 'vue2-hammer'
import VueCalendly from 'vue-calendly'
import VCalendar from 'v-calendar'
import VueSocialSharing from 'vue-social-sharing'
import VueSocial from '@growthbunker/vuesocial'
import VueCountdown from '@chenfengyuan/vue-countdown'

import { v4 as uuidv4 } from 'uuid';

import VueSocketIOExt from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import { format } from 'date-fns'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faFacebookMessenger,
  faFacebookSquare,
  faWhatsappSquare,
  faLinkedin,
  faTelegram,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'

import {
  faColumns,
  faDollarSign,
  faMapMarkerAlt,
  faBuilding,
  faExclamationCircle,
  faInfinity,
  faQuestionCircle,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons'

import { AccountTypes, UserGroups } from '@/constants/enums'
// import { VueReCaptcha } from 'vue-recaptcha-v3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuesax from 'vuesax'
import { bucket } from '@/components/awsS3'
import VueTour from 'vue-tour'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins

import '@/libs/portal-vue'
import '@/libs/toastification'

// START VueSax
import 'vuesax/dist/vuesax.css'

// For more options see below
// Vue.use(VueReCaptcha, { siteKey: '6LcnJY8gAAAAAJgciNQmVPQcuZFRNcCZPiHZTkXb' })

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

const socketURL = process.env.VUE_APP_SOCKET_URL

const socket = io(socketURL, {
  transports: ['websocket', 'polling', 'flashsocket'],
})

library.add(
  faFacebook,
  faFacebookMessenger,
  faFacebookSquare,
  faWhatsappSquare,
  faLinkedin,
  faTelegram,
  faTwitterSquare,
  faDollarSign,
  faMapMarkerAlt,
  faColumns,
  faBuilding,
  faExclamationCircle,
  faInfinity,
  faQuestionCircle,
  faMoneyBill,
)

Vue.use(VueCalendly)

Vue.use(VCalendar)

Vue.use(VueSocialSharing)

Vue.use(VueHammer)

Vue.use(VueSocial)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueSocketIOExt, socket)

Vue.use(vuesax)
// END VueSax

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component(VueCountdown.name, VueCountdown)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$longestTimeout = 10000
Vue.prototype.$fileUploadTimeout = 99999999

Vue.prototype.$toastContent = ToastificationContent

Vue.prototype.$generateUUID4 = () => {
  return uuidv4()
}

Vue.prototype.$checkRights = (section, requiredRight) => {
  const { permissions } = store.state.auth.ActiveUser

  if (section) {
    let allRights = permissions.filter(item => {
      if (item.section === section && item[requiredRight]) {
        return true
      }
    })
    if (allRights.length > 0) {
      allRights = allRights[0]
      return true
    }

    return false
  }

  // if (perm_rights.includes(specific)) {
  //   return true
  // }
  return true
}
Vue.prototype.$checkFullName = fullname => {
  let state = true
  let message = ''

  if (fullname === undefined) {
    state = false
    message = 'A valid fullname is required (only letters allowed)'
  } else {
    if ((fullname.length <= 2)) { // if fullname less than 2 char
      state = false
      message = 'A valid fullname is required (only letters allowed)'
    }

    const formatName = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/
    if (formatName.test(fullname)) { // if contain specaial character
      state = false
      message = 'A valid fullname is required (only letters allowed)'
    }
  }

  return { state, message }
}
Vue.prototype.$checkOfficialName = officialName => {
  let state = true
  let message = ''

  if (officialName === undefined) {
    state = false
    message = 'A valid official name is required'
  } else {
    if ((officialName.length <= 2)) { // if fullname less than 2 char
      state = false
      message = 'A valid official name is required'
    }

    const formatName = /[!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?]+/
    if (formatName.test(officialName)) { // if contain specaial character
      state = false
      message = 'A valid official name is required'
    }
  }

  return { state, message }
}
Vue.prototype.$checkEmail = email => {
  let state = true
  let message = ''

  // if email is not valid
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  if (!email.match(emailRegex)) {
    state = false
    message = 'Please input a valid email address'
  }

  return {
    state,
    message,
  }
}

Vue.prototype.$limitPhoneNumber = phoneNumber => {
  // const result = String(phoneNumber).match('/\d{4}/')
  // return phoneNumber.length() < 8 ? phoneNumber : phoneNumber.substring(0, 8);
  const specialChars = '`!@#$%^&*()_+\-=\[\]{};\':"\\|,.<>\/?~'

  if (phoneNumber === null) {
    return null
  }

  if (phoneNumber.includes(specialChars)) {
    return null
  }

  const result = String(phoneNumber).substring(0, 8)
  return result
}

Vue.prototype.$removeUtmQuery = url => {
  url = new URL(url);
  // localStorage.set()
  if (url.searchParams.get('utm_source')) {
    localStorage.setItem('utm_source', url.searchParams.get('utm_source'))
  }
  if (url.searchParams.get('utm_medium')) {
    localStorage.setItem('utm_medium', url.searchParams.get('utm_medium'))
  }
  if (url.searchParams.get('utm_campaign')) {
    localStorage.setItem('utm_campaign', url.searchParams.get('utm_campaign'))
  }
  url.searchParams.delete('utm_source');
  url.searchParams.delete('utm_medium');
  url.searchParams.delete('utm_campaign');

  const updatedUrl = url.toString();
  return updatedUrl
}

Vue.prototype.$checkPhoneNumber = phoneNumber => {
  let state = true
  let message = ''

  if (phoneNumber === undefined || phoneNumber == null || phoneNumber.length < 8) {
    state = false
    message = 'phone number must be at least 8 digit'
  }

  if (phoneNumber === undefined || phoneNumber == null || phoneNumber.length > 8) {
    state = false
    message = 'max phone number is 8 digit'
  }

  return { state, message }
}
Vue.prototype.$formatDate = oldDate => {
  if (oldDate === undefined || oldDate === '' || oldDate === null) {
    return '-'
  }

  if (typeof oldDate.getMonth === 'function') { // check if date is already a Date()
    return format(oldDate, 'yyyy-MM-dd')
  }

  return format(new Date(oldDate), 'yyyy-MM-dd')
}
Vue.prototype.$formatDatetime = (oldDate, withSeconds = false) => {
  if (oldDate === undefined || oldDate === '' || oldDate === null) {
    return '-'
  }

  let formatString = 'yyyy-MM-dd HH:mm'
  if (withSeconds) {
    formatString = 'yyyy-MM-dd HH:mm:ss'
  }

  if (typeof oldDate.getMonth === 'function') { // check if date is already a Date()
    return format(oldDate, formatString)
  }

  return format(new Date(oldDate), formatString)
}
Vue.prototype.$formatTime = (oldDate, withSeconds = false) => {
  try {
    if (oldDate === undefined || oldDate === '' || oldDate === null) {
      return '-'
    }

    let formatString = 'HH:mm'
    if (withSeconds) {
      formatString = 'HH:mm:ii'
    }

    if (typeof oldDate.getMonth === 'function') { // check if date is already a Date()
      return format(oldDate, formatString)
    }

    return format(new Date(oldDate), formatString)
  } catch (error) {
    return oldDate
  }
}
Vue.prototype.$capitalizeString = string => string.charAt(0).toUpperCase() + string.slice(1)
Vue.prototype.$attachQuerySymbol = value => (value.length > 0 ? '&' : '?')
Vue.prototype.$bucketUrl = `https://${bucket}.s3.ap-southeast-1.amazonaws.com`
Vue.prototype.$generatePresignedUrl = async (file_name = '', method = 'GET', bucket_name = '') => {
  const resp = new Promise((res, rej) => {
    Vue.prototype.$http
      .post('/api/s3/generate-presigned-url', {
        form_data: {
          file_name,
          method,
          bucket,
        },
      })
      .then(response => {
        res(response.data)
      })
      .catch(() => {})
  })

  let result = await resp.then(response => {
    return response
  })

  return result
}

Vue.prototype.$downloadCSV = (arrayOfObject = [], csvFilename = 'data.csv', headers=[]) => { // eslint-disable-line
  let array = typeof arrayOfObject !== 'object' ? JSON.parse(arrayOfObject) : arrayOfObject
  let str = ''

  // make data uniform because some of the field can be missing
  const skeleton = {}
  const titleSkeleton = {}

  // Create the skeleton object
  if (headers.length > 0) {
    headers.forEach(header => {
      skeleton[header.columnName] = ''
      titleSkeleton[header.label] = ''
    })
  } else {
    array.forEach(item => {
      Object.keys(item).forEach(key => {
        skeleton[key] = ''
      })
    })
  }

  // Extend the skeleton with original data
  array = array.map(item => ({ ...skeleton, ...item }))
  // array = array.map(item => ({ ...skeleton }))

  // append header
  let line = ''
  for (const index in titleSkeleton) { // eslint-disable-line
    if (line !== '') line += ','
    line += index
  }
  str += line + '\r\n' // eslint-disable-line

  // append content
  for (let i = 0; i < array.length; i++) { // eslint-disable-line
    line = ''
    for (const index in skeleton) { // eslint-disable-line
      if (line !== '') line += ','
      // line += `"${array[i][index]}"`
      const value = array[i][index] ? array[i][index].toString().replace(/"/g, '`').replace(/"/g, '`') : array[i][index]
      line += `"${value}"`
    }
    str += line + '\r\n' // eslint-disable-line
  }

  const anchor = document.createElement('a')
  // anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(str)}`
  anchor.href = `data:Application/octet-stream,${encodeURIComponent(str)}`
  anchor.target = '_blank'
  anchor.download = csvFilename
  anchor.click()
}

Vue.prototype.$thousandSeparated = value => {
  return value.toLocaleString('en-US')
}

Vue.prototype.$isEmpty = value => {
  if (value === undefined) return true
  if (value === null) return true
  if (value === '') return true
  if (value === 'Not Set') return true

  if (typeof (value) === 'object') {
    if (Object.keys(value).length <= 0) return true
  }

  if (typeof (value) === 'array') {
    if (Object.keys(value).length <= 0) return true
  }

  return false
}
Vue.prototype.$isNotEmpty = value => {
  return !Vue.prototype.$isEmpty(value)
}

Vue.prototype.$formatCurrency = value => {
  if (Vue.prototype.$isNotEmpty(value)) {
    return value.toLocaleString('en-US')
  }
  return value
}

Vue.prototype.$scrollTo = (id, offset = 50) => {
  // window.scrollTo(id, offset)
  function position(obj) {
    let currenttop = 0
    if (obj.offsetParent) {
      do { currenttop += obj.offsetTop - offset}
      while (( obj = obj.offsetParent ))
      return [ currenttop ]
    }
  }
  window.scroll(0, position(document.getElementById(id)));
}

Vue.prototype.$vh = v => {
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
  return (v * h) / 100
}
Vue.prototype.$vw = v => {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}
Vue.prototype.$getGridVh = () => {
  const screenZoom = Math.round(window.devicePixelRatio * 100)
  let vh = 55

  if (screenZoom > 200) {
    vh = 50
  } else if (screenZoom > 175) {
    vh = 70
  } else if (screenZoom > 155) {
    vh = 50
  } else if (screenZoom > 105) {
    vh = 55
  } else if (screenZoom > 90) {
    vh = 65
  } else if (screenZoom > 80) {
    vh = 75
  } else if (screenZoom > 50) {
    vh = 85
  }
  return vh
}

Vue.prototype.$isMobile = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth <= 768) {
    return true
  }
  return false
}

Vue.prototype.$isTablet = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 768 && screenWidth <= 992) {
    return true
  }
  return false
}

Vue.prototype.$isSmallLaptop = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 992 && screenWidth <= 1200) {
    return true
  }
  return false
}

Vue.prototype.$isLargeLaptop = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth
  if (screenWidth > 1200) {
    return true
  }
  return false
}
Vue.prototype.$AccountTypes = AccountTypes
Vue.prototype.$UserGroups = UserGroups

Vue.prototype.$toastContent = ToastificationContent
Vue.prototype.$toastSuccess = (title = 'Success', text = '') => {
  Vue.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'CheckCircleIcon',
        text,
        variant: 'success',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastDanger = (title = 'An Error Occured', text = 'Please try again later.') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'danger',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastWarning = (title = 'Warning', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'warning',
        bodyClass: 'text-break',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}
Vue.prototype.$toastInfo = (title = 'Info', text = '') => {
  Vue.prototype.$toast(
    {
      component: Vue.prototype.$toastContent,
      props: {
        title,
        icon: 'AlertCircleIcon',
        text,
        variant: 'info',
        position: 'center',
      },
    },
    { timeout: Vue.prototype.$longestTimeout },
  )
}

Vue.prototype.$handleErrorResponse = (error, title = 'An Error Occured', message = null) => {
  return new Promise((resolve, reject) => {
    // alert('an error occured')

    // Vue.prototype.$toastDanger(
    //   title,
    //   message || error.response?.data?.message || 'Please try again later.',
    // )

    resolve()
  })
}

new Vue({
  sockets: {
    connect() {
      // console.log('connected!')
    },
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app')
