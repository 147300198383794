export default [
  {
    path: '/marketplace',
    name: 'marketplace',
    component: () => import('@/views/marketplace/Marketplace.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      pageTitle: 'Marketplace | BETA',
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
  {
    path: '/marketplace/:slug',
    name: 'marketplace-details',
    component: () => import('@/views/marketplace/MarketplaceProductDetails.vue'),
    meta: {
      pageTitle: 'Marketplace | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marketplace-edit/:slug',
    name: 'marketplace-edit',
    component: () => import('@/views/marketplace/MarketplaceEditProduct.vue'),
    meta: {
      pageTitle: 'Marketplace | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Edit Product',
          active: true,
        },
      ],
    },
  },
  {
    path: '/wishlist',
    name: 'marketplace-wishlist',
    component: () => import('@/views/marketplace/MarketplaceWishlist.vue'),
    meta: {
      pageTitle: 'Wishlist | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Wishlist',
          active: true,
        },
      ],
    },
  },
  {
    path: '/checkout',
    name: 'marketplace-checkout',
    component: () => import('@/views/marketplace/MarketplaceCheckout.vue'),
    meta: {
      pageTitle: 'Checkout | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-product',
    name: 'marketplace-add-product',
    component: () => import('@/views/marketplace/MarketplaceAddProduct.vue'),
    meta: {
      pageTitle: 'Marketplace | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Add Product',
          active: true,
        },
      ],
    },
  },

  {
    path: '/marketplace-manage-products',
    name: 'marketplace-manage-products',
    component: () => import('@/views/marketplace/Marketplace.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      pageTitle: 'Manage Products & Services | BETA',
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
  {
    path: '/marketplace-my-checkouts',
    name: 'marketplace-checkouts-list',
    component: () => import('@/views/marketplace/marketplace-checkout-list/MarketplaceCheckoutList.vue'),
    meta: {
      pageTitle: 'My Checkouts | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marketplace-orders',
    name: 'marketplace-orders-list',
    component: () => import('@/views/marketplace/marketplace-orders-list/MarketplaceOrdersList.vue'),
    meta: {
      pageTitle: 'Orders | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/marketplace-orders/:id',
    name: 'marketplace-orders-view',
    component: () => import('@/views/marketplace/marketplace-orders-view/MarketplaceOrdersView.vue'),
    meta: {
      pageTitle: 'Checkouts | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
  {
    path: '/marketplace-checkouts/:id',
    name: 'marketplace-checkouts-view',
    component: () => import('@/views/marketplace/marketplace-checkout-view/MarketplaceCheckoutView.vue'),
    meta: {
      pageTitle: 'Checkouts | BETA',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Marketplace', active: true },
      ],
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Marketplace',
    },
  },
]
