export default [
  {
    path: '/help-desks',
    name: 'help-desks-index',
    component: () => import('@/views/help-desks/HelpDesks.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Management', active: true },
      ],
      pageTitle: 'Tickets | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/help-desks/issues/live-chat',
    name: 'help-desks-issues-live-chat',
    component: () => import('@/views/help-desks/help-desks-issues/View.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Management', active: true },
      ],
      pageTitle: 'Issue | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/help-desks/issues/:id?',
    name: 'help-desks-issues-view',
    component: () => import('@/views/help-desks/help-desks-issues/View.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Management', active: true },
      ],
      pageTitle: 'Issue | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/help-desks/issues-queues',
    name: 'help-desks-index-queues-index',
    component: () => import('@/views/help-desks/help-desks-issues-queues/HelpDesksIssuesQueues.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'User Management', active: true },
      ],
      pageTitle: 'Help Desk | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },
]
