export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/roles/Roles.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Roles', active: true },
      ],
      pageTitle: 'Roles | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/roles/:id',
    name: 'roles-view',
    component: () => import('@/views/roles/roles-view/RolesView.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Roles', active: true },
      ],
      pageTitle: 'Roles Details | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/roles-create',
    name: 'roles-create',
    component: () => import('@/views/roles/RolesForm.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Roles', active: true },
      ],
      pageTitle: 'Create Roles | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },

  {
    path: '/roles-edit/:id',
    name: 'roles-edit',
    component: () => import('@/views/roles/RolesForm.vue'),
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'Roles', active: true },
      ],
      pageTitle: 'Edit Roles | BETA',
      rule: 'editor',

      allowAnonymous: false,
    },
  },
]
