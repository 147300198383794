export default [
  {
    path: '/email',
    name: 'email',
    component: () => import('@/views/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'email',
      rule: 'editor',
      // group: ['agency', 'employer', 'education_institution'],
      allowAnonymous: true,
      section: 'Email',
    },
  },
  {
    path: '/email/:folder',
    name: 'email-folder',
    component: () => import('@/views/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/email/label/:label',
    name: 'email-label',
    component: () => import('@/views/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'employer', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },

]
