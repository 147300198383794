import {
  getUserInfo, getOtherProfiles, getInterviewerLink, getMeetingEndLink, getApplicantLink,
} from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import router from '@/router'

export default {
  namespaced: true,
  state: {
    ActiveUser: getUserInfo(),
    OtherProfiles: getOtherProfiles(),
    online_users: [],
    userImg: 'default',
    specificEvent: {},
    platformJobs: [],
    selected_ats_job: {},
    events_data: [],
    federation_info: {},
    event_applications: [],
    event_company_image: '',
    interviewer_link: getInterviewerLink(),
    meeting_end_link: getMeetingEndLink(),
    applicant_link: getApplicantLink(),
    incoming_interview: null,
  },
  getters: {

  },
  mutations: {
    UPDATE_PROFILE(state, payload) {
      state.userImg = payload
    },
    UPDATE_FEDERATION_INFO(state, payload) {
      state.federation_info = payload
    },
    CHANGE_ONLINE_USERS(state, val) {
      state.online_users = val
    },
    UPDATE_MULTIPLE_PROFILES(state, payload) {
      const OtherProfiles = JSON.parse(localStorage.getItem('OtherProfiles')) || state.OtherProfiles
      state.OtherProfiles.push(payload)
      OtherProfiles.push(payload)
      localStorage.setItem('OtherProfiles', JSON.stringify(OtherProfiles))
    },

    CHANGE_MULTIPLE_PROFILES(state, payload) {
      state.OtherProfiles = payload
      localStorage.setItem('OtherProfiles', JSON.stringify(payload))
    },

    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
      // Get Data localStorage
    //   const userInfo = JSON.parse(localStorage.getItem('userInfo')) || state.ActiveUser
      state.ActiveUser = payload
      // Store data in localStorage
      localStorage.setItem('userInfo', JSON.stringify(payload))
    },

    UPDATE_EDIT_MODE(state, payload) {
      state.editMode = payload
    },

    // Events Mutations
    UPDATE_EVENT(state, val) {
      state.specificEvent = val
    },

    UPDATE_PLATFORM_JOBS(state, val) {
      state.platformJobs.push(...val)
    },

    CREATE_PLATFORM_JOBS(state, val) {
      state.platformJobs = val
    },

    UPDATE_PLATFORM_JOBS_ITEM(state, val) {
      state.platformJobs[val.index] = {
        ...state.platformJobs[val.index],
        ...val.updates,
      }
    },

    UPDATE_SELECTED_ATS_JOB(state, val) {
      state.selected_ats_job = val
    },

    UPDATE_EVENTS_DATA(state, val) {
      state.events_data.push(...val)
    },

    CREATE_EVENTS_DATA(state, val) {
      state.events_data = val
    },

    CREATE_APPLICANTS_DATA(state, val) {
      state.event_applications = val
    },

    UPDATE_COMPANY_IMAGE(state, val) {
      state.event_company_image = val
    },
    UPDATE_INTERVIEWER_LINK(state, val) {
      state.interviewer_link = val
      localStorage.setItem('interviewer_link', val)
    },
    UPDATE_MEETING_END_LINK(state, val) {
      state.meeting_end_link = val
      localStorage.setItem('meeting_end_link', val)
    },
    UPDATE_APPLICANT_LINK(state, val) {
      state.applicant_link = val
      localStorage.setItem('applicant_link', val)
    },
    UPDATE_INCOMING_INTERVIEW(state, val) {
      state.incoming_interview = val
    },
  },
  actions: {

    approveLogin({ commit }, output) {
      if (output.isHiringManager) {
        commit('UPDATE_MULTIPLE_PROFILES', output.response.data, {
          root: true,
        })
      } else {
        localStorage.setItem('accessToken', output.response.data.token)
        const instance = output.router_app

        if (instance) {
          instance.$emit('login-state', true)
        }
        // Update user details
        commit('auth/UPDATE_USER_INFO', output.response.data, { root: true })
        // Set bearer token in axios
        useJwt.setToken(output.response.data.token)
        router.push('/')
      }
    },

    getLoggedInUser({ commit }, ctx) {
      const instance = ctx.router_app
      const { publicPage } = ctx
      const { token } = instance.$store.state.auth.ActiveUser || { token: null }

      if (token) {
        instance.$http.defaults.headers.common.Authorization = `Token ${token}`
        instance.$http
          .get('/api/user')
          .then(response => {
            if (response.data.password_expired) {
              localStorage.removeItem('userInfo')
              localStorage.removeItem('email')
              localStorage.removeItem('accessToken')
              if (!publicPage) {
                router.push(response.data.link)
                instance.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Password Expiry Alert',
                      icon: 'AlertCircleIcon',
                      text: 'Your previous password has already expired. You can reset your password below',
                      variant: 'danger',
                    },
                  },
                  { timeout: instance.$longestTimeout },
                )
              }
            } else if (response.data.show_session) {
              localStorage.removeItem('userInfo')
              localStorage.removeItem('email')
              localStorage.removeItem('accessToken')
              if (!publicPage) {
                router.push('/auth/login')
              }
            } else if (response.data.errors) {
              if (
                response.data.errors[0] === 'User not found'
          || response.data.errors[0] === 'Could not validate credentials'
              ) {
                localStorage.removeItem('userInfo')
                localStorage.removeItem('email')
                localStorage.removeItem('accessToken')
                if (!publicPage) {
                  router.push('/auth/login')
                }
              }
            } else {
              const returned_data = response.data
              commit('UPDATE_USER_INFO', response.data)
              const applicationUrl = localStorage.getItem('application_url')

              instance.current_event = localStorage.getItem('current_event')
                ? localStorage.getItem('current_event')
                : ''
              if (
                returned_data.account_type === this.$AccountTypes.APPLICANT
                  && router.currentRoute.path !== '/profile'
              ) {
                if (returned_data.profile_status.incomplete) {
                  if (publicPage === null) {
                    // instance.$toast(
                    //   {
                    //     component: ToastificationContent,
                    //     props: {
                    //       title: 'Incomplete Profile Alert',
                    //       icon: 'AlertCircleIcon',
                    //       text: 'Please fill out your profile and update your resume in order to access the other sections',
                    //       variant: 'danger',
                    //     },
                    //   },
                    //   { timeout: instance.$longestTimeout },
                    // )
                    // router.push('/profile')
                  }
                } else {
                  if (applicationUrl) {
                  localStorage.removeItem('application_url')
                  // window.location.href = applicationUrl
                  window.open(application_url, '_blank').focus()
                    
                  }
                }
              }
            }
          })
          .catch(error => {
          })
      }
    },
    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },

    updateMultipleProfiles({ commit }, payload) {
      commit('UPDATE_MULTIPLE_PROFILES', payload)
    },

    updateEventInfo({ commit }, payload) {
      commit('UPDATE_EVENT', payload)
    },

    updatePlatformJobs({ commit }, payload) {
      commit('UPDATE_PLATFORM_JOBS', payload)
    },

    createPlatformJobs({ commit }, payload) {
      commit('CREATE_PLATFORM_JOBS', payload)
    },

    createSelectedJob({ commit }, payload) {
      commit('UPDATE_SELECTED_ATS_JOB', payload)
    },

    updatePlatformJobsItem({ commit }, payload) {
      commit('UPDATE_PLATFORM_JOBS_ITEM', payload)
    },

    updateEventsData({ commit }, payload) {
      commit('UPDATE_EVENTS_DATA', payload)
    },

    createEventsData({ commit }, payload) {
      commit('CREATE_EVENTS_DATA', payload)
    },

    updateCompanyImage({ commit }, payload) {
      commit('UPDATE_COMPANY_IMAGE', payload)
    },

    updateInterviewerLink({ commit }, payload) {
      commit('UPDATE_INTERVIEWER_LINK', payload)
    },

    updateMeetingEndLink({ commit }, payload) {
      commit('UPDATE_MEETING_END_LINK', payload)
    },

    updateApplicantLink({ commit }, payload) {
      commit('UPDATE_APPLICANT_LINK', payload)
    },

    updateIncomingInterview({ commit }, payload) {
      commit('UPDATE_INCOMING_INTERVIEW', payload)
    },
  },
}
