import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import BootstrapVue from 'bootstrap-vue'
import ProfileCompletionForm from '@/components/ProfileCompletionForm.vue'

import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import CeaseBanner from '@/components/CeaseBanner.vue'


import Multiselect from 'vue-multiselect'

Vue.use(BootstrapVue)

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('multiselect', Multiselect)

Vue.component('ProfileCompletionForm', ProfileCompletionForm)

Vue.component('StatisticCardHorizontal', StatisticCardHorizontal)
Vue.component('StatisticCardVertical', StatisticCardVertical)

Vue.component('CeaseBanner', CeaseBanner)
