<template>
  <b-button
    variant="primary"
    disabled
    block
  >
    <b-spinner small />
    Loading...
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },

}
</script>
