<template>
  <!-- Profile Completion Modal -->
  <div class="">
    <b-modal
      ref="popupProfileQuestions"
      v-model="popupProfileQuestions"
      class="profile-questions-modal"
      centered
      hide-footer
      :hide-header-close="hideHeader"

      no-close-on-backdrop
      :title="`Complete Your Profile ${profileQuestionList.indexOf(currentProfileQuestion) +1} / ${profileQuestionList.length}`"
      size="lg"
      @close="closePopup"
    >
    
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center mb-2 mt-1"
      >
        <b-spinner
          variant="warning"
          type="grow"
          class=""
          style="width: 2rem; height: 2rem;"
        />
      </div>
      <div
        class="w-100"
      >
        <!-- <h6 class="mb-6">
              You will need to complete your profile before applying
            </h6> -->
        <validation-observer ref="profileCompletionForm">
          <b-form class="profile-completion-form">
            <div class="typeform-question-container">
              <!-- upload resume -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'upload_resume'"
                :current-profile-question="currentProfileQuestion"
                :question="`Our system can help you fill up your education and employment
                      information based on your resume. Do you want to upload your resume
                      now?`"
                :disable-next-button="!user.resume_file"
                :disable-previous-button="profileQuestionList.indexOf('upload_resume') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <div
                  id="upload-resume"
                  class="w-full"
                >
                  <file-pond
                    ref="pond-resume"
                    name="resume-upload"
                    label-idle="Upload your resume file."
                    accepted-file-types="application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    :server="resumeServer"
                    :files="resumeFiles"
                    credits="false"
                    @init="handleResumePondInit"
                  />
                  <!-- <input
                    ref="uploadResume"
                    class="mt-6 hidden"
                    type="file"
                    accept=""
                    @change="updateFiles"
                  > -->

                  <!-- <b-button
                        v-if="user.resume_file && normal == true"
                        id="#button-with-loading"
                        class="mt-1 mr-2 w-full shadow-lg"
                        variant="primary"
                        @click="viewUploadedResume"
                      >View your resume</b-button> -->
                </div>

                <div
                  v-if="user.resume_file_url !== ''"
                  class="h-screen my-2 w-full"
                  style="height: 50vh;"
                >
                  <iframe
                    v-if="document_type == 'pdf'"
                    :src="file_source"
                    width="100%"
                    height="100%"
                    frameborder="0"
                  />

                  <iframe
                    v-else
                    :src="file_source"
                    width="100%"
                    height="100%"
                    frameborder="0"
                  />
                </div>
              </typeform-question-holder>

              <!-- gender -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'gender'"
                :current-profile-question="currentProfileQuestion"
                :question="`Please select your gender`"
                :disable-next-button="!user.gender
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('gender') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Gender"
                    rules="required"
                  >
                    <v-select
                      id="Gender"
                      v-model="user.gender"
                      :danger="errors.length > 0 ? false : null"
                      danger-text="Please select your gender"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="genderOptions"
                      placeholder="Please select your gender"
                      :reduce="value => value.value"
                      label="value"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- date of birth -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'date_of_birth'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your date of birth?`"
                :disable-next-button="!user.date_of_birth
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('date_of_birth') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-date-of-birth"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Date Of Birth"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="user.date_of_birth"
                      class="form-control"
                      :config="dobConfig"
                      placeholder="Date of birth"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- highest qualifications -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'highest_qualification'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your highest qualification?`"
                :disable-next-button="!user.highest_qualification
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('highest_qualification') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-highest-qualification"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Highest Qualification"
                    rules="required"
                  >
                    <v-select
                      v-model="user.highest_qualification"
                      :danger="
                        user.highest_qualification !== 'Not Set' &&
                          user.highest_qualification !== ''
                          ? false
                          : true
                      "
                      danger-text="Please select your highest qualification(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      :options="highestQualifications"
                      label="value"
                      :reduce="value => value.value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- employment_status -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'employment_status'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your current employment status?`"
                :disable-next-button="!user.employment_status
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('employment_status') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-employment-status"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Employment Status"
                    rules="required"
                  >
                    <v-select
                      v-model="user.employment_status"
                      :danger="errors.length > 0 ? false : null"
                      danger-text="Please select your employment status"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      placeholder="Please select your employment status"
                      :options="employmentStatusOptions"
                      :reduce="value => value.value"
                      label="value"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- citizenship -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'citizenship'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your citizenship?`"
                :disable-next-button="!user.citizenship
                  || getErrors('profileCompletionForm')[0] !== undefined
                  || getErrors('profileCompletionForm')[1] !== undefined
                  || (user.citizenship === 'Others' ? (user.citizenship_others === undefined || user.citizenship_others ? true : false) : false )
                "
                :disable-previous-button="profileQuestionList.indexOf('citizenship') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-citizenship"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Citizenship"
                    rules="required"
                  >
                    <v-select
                      v-model="user.citizenship"
                      :danger="errors.length > 0 ? false : null"
                      danger-text="Please select your citizenship"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      placeholder="Please select your citizenship"
                      :options="citizenshipOptions"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                  <validation-provider
                    v-if="user.citizenship === 'Others'"
                    #default="{ errors }"
                    name="Other Citizenship"
                    rules="required|alpha|min:3"
                  >

                    <v-select
                      v-if="user.citizenship === 'Others'"
                      v-model="user.citizenship"
                      danger-text="Please select your citizenship(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full mt-1"
                      :options="allCitizenships"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- preferred_location -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'preferred_location'"
                :current-profile-question="currentProfileQuestion"
                :question="`Where is your preferred working location?`"
                :disable-next-button="!user.preferred_location || user.preferred_location.length <= 0
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('preferred_location') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-pref-location"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Preferred Location"
                    rules="required"
                  >
                    <v-select
                      v-model="user.preferred_location"
                      :danger="errors.length > 0 ? false : null"
                      danger-text="Please select your preferred work location"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      class="w-full"
                      multiple
                      placeholder="Please select your preferred work location"
                      :options="prefWorkLocationOptions"
                      :reduce="value => value.value"
                      label="value"
                      :menu-props="{
                        closeOnClick: false,
                        closeOnContentClick: false,
                      }"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- preferred_industry -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'preferred_industry'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your preferred industry?`"
                :disable-next-button="!user.preferred_industry || user.preferred_industry.length <= 0
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('preferred_industry') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-pref-industry"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Preferred Industry"
                    rules="required"
                  >
                    <v-select
                      v-model="user.preferred_industry"
                      class="w-full"
                      multiple
                      :danger="errors.length > 0 ? false : null"
                      danger-text="Please select your preferred job industry"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      placeholder="Please select your preferred job industry"
                      :options="prefIndustryOptions"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- preferred_position -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'preferred_position'"
                :current-profile-question="currentProfileQuestion"
                :question="`What is your preferred position?`"
                :disable-next-button="!user.preferred_position
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('preferred_position') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-pref-position"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Preferred Position"
                    rules="required"
                  >
                    <!-- <b-form-input
                          v-model="user.preferred_position"
                          :danger="errors.length > 0 ? false : null"
                          danger-text="Please input your preferred job position"
                          class="w-full"
                          placeholder="Preferred Job Position (Separate multiple positions with a comma)"
                          val-icon-danger="icon-x"
                          val-icon-pack="feather"
                        /> -->
                    <!-- <v-select
                      v-model="user.preferred_position"
                      taggable
                      multiple
                      no-drop
                      :map-keydown="selectHandlers"
                      :options="preferred_position_options"
                      :reduce="value => value.value"
                      :create-option="option => option.toLowerCase()"
                    /> -->
                    <v-select
											v-model="user.preferred_position"
                      class="w-full"
                      multiple
                      danger-text="Please select your preffered job position(required)"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      :options="preferred_position_options"
                      label="value"
                      :reduce="value => value.value"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
              </typeform-question-holder>

              <!-- postal -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'postal_code'"
                :current-profile-question="currentProfileQuestion"
                :question="`Please enter your Residential Code`"
                :disable-next-button="!user.postal_code
                  || getErrors('profileCompletionForm')[0] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('postal_code') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-postal"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Residential Code"
                    rules="required|min:6"
                  >
                    <b-form-input
                      v-model="user.postal_code"
                      class="w-full"
                      placeholder="Residential Postal Code"
                      :danger="errors.length > 0 ? false : null"
                      danger-text="A valid residential postal is required"
                      val-icon-danger="icon-x"
                      val-icon-pack="feather"
                      maxlength="6"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- availability -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'availability'"
                :current-profile-question="currentProfileQuestion"
                :question="`When will you be available?`"
                :disable-next-button="!user.availability_number || !user.availability_period
                  || getErrors('profileCompletionForm')[0] !== undefined
                  || getErrors('profileCompletionForm')[1] !== undefined
                "
                :disable-previous-button="profileQuestionList.indexOf('availability') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <b-form-group
                  label-for="register-availability"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Notice Period"
                    rules="required"
                  >
                    <div class="d-flex justify-content-between w-100">
                      <b-form-input
                        v-model="user.availability_number"
                        class="w-50 mx-1"
                        type="text"
                        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                        :danger="
                          user.availability_period ? true : false
                        "
                        danger-text="Please input how many days, weeks, months when you are available to start the job"
                        placeholder="Please input a number"
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                      />

                      <v-select
                        v-model="user.availability_period"
                        :danger="
                          user.availability_period !== 'Not Set' && user.availability_period !== ''
                            ? false
                            : true
                        "
                        danger-text="Please select the period when you are available to start the job"
                        val-icon-danger="icon-x"
                        val-icon-pack="feather"
                        class="w-50 mx-1"
                        placeholder="Select a period"
                        :options="allAvailabilityOptions"
                        :reduce="value => value.value"
                        label="value"
                      />
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </typeform-question-holder>

              <!-- employments -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'employments'"
                :current-profile-question="currentProfileQuestion"
                :question="`Employment Information`"
                :disable-next-button="!employmentInfo || employmentInfo.length <= 0
                  && (employmentInfo[0] ? (employmentInfo[0].title || employmentInfo[0].title === undefined) : true)
                "
                :disable-previous-button="profileQuestionList.indexOf('employments') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <div class="mb-1">
                  You currently have
                  <b> {{ employmentInfo ? employmentInfo.length : 0 }} </b> Employment(s)

                  <template v-if="!employmentInfo || employmentInfo.length <= 0">
                    , at least 1 is required to apply
                  </template>
                </div>

                <employment-form
                  @employments-updated="employmentsUpdated"
                />
              </typeform-question-holder>

              <!-- educations -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'educations'"
                :current-profile-question="currentProfileQuestion"
                :question="`Education Information`"
                :disable-next-button="
                  (!educationInfo || educationInfo.length <= 0)
                    || ( $isEmpty(user.highest_qualification) )
                "
                :disable-previous-button="profileQuestionList.indexOf('educations') === 0"
                @previous-question="previousProfileQuestion"
                @next-question="nextProfileQuestion"
              >
                <div class="">
                  <h5>
                    What is your highest qualification?
                  </h5>

                  <div class="mx-2">
                    <b-form-group
                      label-for="register-highest-qualification"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Highest Qualification"
                        rules="required"
                      >
                        <v-select
                          v-model="user.highest_qualification"
                          :danger="
                            user.highest_qualification !== 'Not Set' &&
                              user.highest_qualification !== ''
                              ? false
                              : true
                          "
                          danger-text="Please select your highest qualification(required)"
                          val-icon-danger="icon-x"
                          val-icon-pack="feather"
                          class="w-full"
                          :options="highestQualifications"
                          label="value"
                          :reduce="value => value.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>

                <div class="mb-1 mt-2">
                  You currently have
                  <b> {{ educationInfo ? educationInfo.length : 0 }} </b> Education(s)

                  <template v-if="!educationInfo || educationInfo.length <= 0">
                    , at least 1 is required to apply
                  </template>
                </div>

                <education-form
                  @educations-updated="educationsUpdated"
                />

                <!-- <b-button
                      @click="popupEducations = !popupEducations"
                    >
                      Manage Educations
                    </b-button> -->
              </typeform-question-holder>

              <!-- submit -->
              <typeform-question-holder
                v-if="currentProfileQuestion === 'submit'"
                :current-profile-question="currentProfileQuestion"
                :question="`Complete your account?`"
                :disable-next-button="true"
                @previous-question="previousProfileQuestion"
              >
                <SpinnerBtn v-if="isLoading" />
                <b-button
                  v-else
                  id="buttonSubmit-profile_completion"
                  variant="primary"
                  block
                  type="submit"
                  @click.prevent="submitProfileUpdate(true)"
                >
                  Finish
                </b-button>
              </typeform-question-holder>
            </div>
          </b-form>
        </validation-observer>
      </div>

    </b-modal>
  </div>
</template>

<script>
import SpinnerBtn from '@/layouts/components/spinners/SpinnerButton.vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TypeformQuestionHolder from '@/components/TypeformQuestionHolder.vue'
import EmploymentForm from '@/views/profile/components/EmploymentForm.vue'
import EducationForm from '@/views/profile/components/EducationForm.vue'
import { listingsMixin } from '@/views/event-management-system/mixins/listingsMixins'
import { allCitizenships } from '@/constants/datastore'
import { singleUpload } from '@/components/awsS3'
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'
import {
  orderBy,
} from 'lodash'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,

)

export default {
  components: {
    FilePond,
    flatPickr,
    vSelect,
    SpinnerBtn,
    ValidationProvider,
    ValidationObserver,
    TypeformQuestionHolder,

    EmploymentForm,
    EducationForm,
  },
  mixins: [listingsMixin],
  props: {
    hideHeader: { type: Boolean, default: false },
    activatePopup: { type: Boolean, default: false },
    userDetails: { type: Array, default: null },
  },
  data() {
    return {
      dobConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'M j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      resumeServer: {

      },
      resumeFiles: null,

      user: {
        resume_file: null,
      },
      document_type: 'pdf',
      file_source: null,
    }
  },

  watch: {
    activatePopup: {
      handler(val, oldVal) {
        if (val) {
          this.user = this.$store.state.auth.ActiveUser
          this.afterUserLoggedIn()
          this.popupProfileQuestions = true
          this.popupReg = false
          this.popup_quiz = false
        }
      },
    },

    'user.resume_file': function (newVal) {
      this.$http
        .put('/api/user', {
          user: { resume_file: this.user.resume_file },
        })
        .then(response => {
          // Math.random() is to prevent image being cache and display the previous file, when user upload or changed the resume file
          // const file_url = `/${this.user.resume_file_url}?${Math.random(0, 30000000)}`
          const file_url = `${this.user.resume_file_url}`

          // this.getFile(file_url)
          if (this.user.resume_file.endsWith('pdf')) {
            this.document_type = 'pdf'
            this.file_source = file_url
          } else {
            this.document_type = 'docs'
            this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${file_url}`
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        //
        })
    },

    deep: true,
  },
  created() {
    this.isLoading = true
    this.allCitizenships = orderBy(allCitizenships, ['value'], ['asc'])
  },

  methods: {
    handleResumePondInit() {
      const { id } = this.$store.state.auth.ActiveUser
      const outerThis = this
      this.$refs['pond-resume']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-resume', `${id}.${extension}`, load, error, progress, abort, null, outerThis)
          },
        },
      })

      // FilePond instance methods are available on `this.$refs.pond`
    },
    closePopup(evt) {
      this.popupProfileQuestions = false
      this.$emit('open-completion-form', false)
    },

    updateFiles(input) {
      const { id } = this.$store.state.auth.ActiveUser
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Resume Upload',
            icon: 'AlertCircleIcon',
            text: 'Resume upload in progress.',
            variant: 'warning',
            progress: 0,
            progressId: 'uploadResume',
          },
        },
        { timeout: this.$fileUploadTimeout },
      )
      this.uploadLoading = true
      this.uploadText = 'Uploading...'
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}
        const fd = new FormData()
        fd.append('file', input.target.files[0])
        // delete this.$http.defaults.headers.common["Authorization"];

        this.$http
          .post(`/api/upload-resume/${email}`, fd, {
            onUploadProgress(progressEvent) {
              const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
              const parent = document.querySelector('#uploadResume')
              parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
            },
          })
          .then(response => {
            this.resume_file = response.data.filename
            // let image_url = response.data.data.display_url;
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Resume Upload',
                  icon: 'CheckCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.user.resume_file = response.data.filename
            this.isLoading = true
            this.uploadLoading = false
            this.uploadText = 'Upload your resume'
            this.viewUploadedResume()
            this.getEmployments()
            this.getEducations()
          })
          .catch(error => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Resume Upload',
                  icon: 'AlertCircleIcon',
                  text: 'Resume upload failed. Try again later.',
                  variant: 'warning',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.uploadLoading = false
            this.uploadText = 'Upload your resume'
          })
        reader.readAsDataURL(input.target.files[0])
      }
    },

    // typeform
    nextProfileQuestion(event = false) { // event variable is not used, not ssre hy but the $emit on child requires a second param
      const index = this.profileQuestionList.indexOf(this.currentProfileQuestion) // get current question index

      if (this.currentProfileQuestion === 'upload_resume') {
        // do nothing
        this.submitProfileUpdate(false, true)
      } else if (this.currentProfileQuestion === 'educations') {
        this.submitProfileUpdate(false, false)
        // do nothing
      } else if (this.currentProfileQuestion === 'educations') {
        // do nothing
      } else {
        this.submitProfileUpdate(false, false)
      }

      if (index + 1 < this.profileQuestionList.length) {
        this.currentProfileQuestion = this.profileQuestionList[index + 1] // get tht next in array
      }
    },
    previousProfileQuestion(event = false) {
      const index = this.profileQuestionList.indexOf(this.currentProfileQuestion) // get current question index

      if (index === 0) return

      this.currentProfileQuestion = this.profileQuestionList[index - 1] // get tht previous in array
    },
    async extractResumeInformation() {
      await this.$http
        .get('/api/extract-resume-info')
        .then(response => {

        })
        .catch(() => {})
    },

    async submitProfileUpdate(closePopupProfileQuestions = false, resumeUpload = false) {
      const proceed = true
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      const form_data = this.user
      this.isLoading = true

      await this.$http
        .put('/api/user', {
          user: form_data,
        })
        .then(response => {
          if (response.data.errors) {
            if (response.data.errors[0] === 'User not found') {
              localStorage.removeItem('userInfo')
              localStorage.removeItem('email')
              localStorage.removeItem('accessToken')
              this.$router.push('/auth/login')
            }
          } else if (response.data.password_error) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Password Error',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            // this.updateConfigInfo()
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Profile Update',
                  icon: 'AlertCircleIcon',
                  text: 'Your profile has been updated successfully!',
                  variant: 'success',
                },
              },
            )

            this.checkProfileCompleteness(response.data)

            // this.getUser() // ! Sugianto 20230628 this is causing the form popup to close before completing all question will comment it out for now
          }
          if (resumeUpload) {
            this.extractResumeInformation()
          }
          this.$store.commit('auth/UPDATE_USER_INFO', response.data)
          this.resume_file = null
          this.isLoading = false
          // this.latestUpdates.account = false
          if (closePopupProfileQuestions) {
            const event = JSON.parse(localStorage.getItem('applied_job'))
            // ! temporary work around, to solve the green button Apply now button not changing to Applyied
            this.popupProfileQuestions = false
            event.refresh_page = true
            this.joinEvent(event, null)
          }
        })
        .catch(error => {
          console.error(error)
        //
        })
    },
    async checkProfileCompleteness(response_data) {
      // this.current_event = localStorage.getItem('current_event')
      //   ? localStorage.getItem('current_event')
      //   : ''
      if (!response_data.profile_status.incomplete) {
        const all_data = { completed_profile: true }
        await this.$http
          .put('/api/account-config', { data: all_data })
          .then(response => {
            const { config } = response.data
          })
          .catch(error => {})
      }
    },

    employmentsUpdated() {
      this.popupEmployments = false
      // this.employmentInfo refreshed by watch: popupEmployments

      this.getEmployments()
    },
    educationsUpdated() {
      this.popupEducations = false
      // this.educationInfo refreshed by watch: popupEducations

      this.getEducations()
    },

    selectHandlers: (map, vm) => ({
      ...map,
      50: e => {
        e.preventDefault()
      },
    }),
    getErrors(scope) {
      try {
        const { errors } = this.$refs[scope]
        const parsed = []

        Object.keys(errors).map(key => {
          parsed.push(errors[key][0])
        })

        return parsed
      } catch {
        return false
      }
    },
  },
}
</script>

<style lang="scss">
  .flatpickr-monthDropdown-month{
    background-color: #fff!important;
  }

</style>
