export default [
  {
    path: '/404',
    name: 'page-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },
  {
    path: '/unauthorized',
    name: 'page-401',
    component: () => import('@/views/error/Error401.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },
  {
    path: '/construction',
    name: 'page-build',
    // component: () => import('@/views/error/Construction.vue'),
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },

  {
    path: '/payment-success/:product_code/:success_code?',
    name: 'Payment Successful',
    component: () => import('@/views/payments/PaymentSuccess.vue'),
    meta: {
      group: ['agency', 'employer'],
      allowAnonymous: true,
      layout: 'full',
    },
  },
  {
    path: '/await-approval',
    name: 'await-approval',
    // component: () => import('@/views/error/Construction.vue'),
    component: () => import('@/views/error/AwaitApproval.vue'),
    meta: {
      allowAnonymous: true,
      layout: 'full',
    },
  },
]
